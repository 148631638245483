import { Injectable } from '@angular/core';

import { AppConfig } from '../../../core/config/app.config';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private _format: string;
  private _locale: string;

  public constructor(private appConfig: AppConfig) {
    this._format = this.appConfig.config.displayFormat;
    this._locale = this.appConfig.config.locale;
  }

  public get format(): string {
    return this._format;
  }

  public set format(value: string) {
    this._format = value;
  }

  public get locale(): string {
    return this._locale;
  }

  public set locale(value: string) {
    this._locale = value;
  }

  //
  // public handleCustomDataFormat(formats: CustomDateFormatDataInterface, date): string {
  //
  //   return formats ? moment(date).format(this.getFormat(formats)) : moment(date).format(this.format);
  // }

  // public getFormat(formats: CustomDateFormatDataInterface): string {
  //   let formatString = '';
  //   try {
  //     formats.formats.forEach((item, index, length) => {
  //       formatString += item.format + (index !== formats.formats.length - 1 ? formats.delimiter : '');
  //     });
  //   } catch (err) {
  //     return formatString;
  //   }
  //   return formatString.length ? formatString : this.appConfig.config.displayFormat;
  // }
}
