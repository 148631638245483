/* src/app/form/list-form/list-form.component.scss */
::ng-deep .project-list-menu {
  max-height: 200px;
}
::ng-deep .list-form-name-icon {
  width: 1.25rem;
  min-width: 1.25rem;
}
.user-info {
  max-width: 150px;
}
/*# sourceMappingURL=list-form.component.css.map */
