/* src/app/question/rules-list/rules-list.component.scss */
#rules-container {
  display: grid;
  grid-template-rows: auto 50px;
  height: 100%;
}
#rules-container .rules-btn-row {
  border-radius: 8px;
  height: 40px;
  padding: 8px;
  border: 1px solid #d7d7d7;
}
#rules-container .rules-btn-row .rule-counter {
  border-radius: 50%;
  background: #e3e7f2;
  font-size: 12px;
  line-height: 21px;
  padding: 0 8px;
}
#rules-container #active-rule-set {
  padding: 8px;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
}
#rules-container #rules-content {
  overflow: auto;
  padding: var(--rightBarPadding);
}
#rules-container #rules-actions {
  padding: 0 10px;
  border-top: 1px solid #babbbd !important;
}
/*# sourceMappingURL=rules-list.component.css.map */
