import { Component, inject, Injector, Input } from '@angular/core';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { ElementTypePipe } from '../../../shared/element-type/element-type.pipe';
import { AppState, getState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { selectCurrentQuestionPendingChanges } from '../../store/question.state';
import { PendingChangesService } from '../../../shared/pending-changes.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'phar-editor-template',
  templateUrl: 'editor-template.component.html',
  styleUrl: 'editor-template.component.scss',
  imports: [ElementTypePipe, AsyncPipe, MatButton, MatIcon],
  standalone: true,
})
export class EditorTemplateComponent {
  @Input() firstTitle = 'General';
  @Input() secondTitle = 'Response settings';
  @Input() controlType: FormElementsEnum;
  injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  pendingChangesService: PendingChangesService = inject(PendingChangesService);
  hasPendingChanges = toSignal(this.store.select(selectCurrentQuestionPendingChanges), {
    injector: this.injector,
  });

  triggerSave(): void {
    const controlID = getState(this.store).control.current.control.controlID;
    this.pendingChangesService.triggerSave(controlID);
  }

  triggerDiscard(): void {
    this.pendingChangesService.triggerDiscardChanges();
  }
}
