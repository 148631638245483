import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { resetCurrentForm } from '../store/form.actions';
import { FormModel, FormTypeEnum } from '../form.model';
import { setContextTitle } from '../../store/actions/ui.actions';
import { slideInOut } from '../../animations';
import { FormRouteData, NavigateBackLinkInfo } from '../../core/models/route-data.interface';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './new-form.component.html',
  styleUrls: ['./new-form.component.scss'],
  animations: [slideInOut],
})
export class NewFormComponent implements OnInit {
  sidebar: Observable<string>;

  form: Observable<FormModel>;

  secondLevelLinks = [
    {
      text: 'Properties',
      url: 'details',
    },
    {
      text: 'Questions',
      url: 'content',
      isDisabled: true,
    },
    {
      text: 'Approvals',
      url: 'approvals',
      isDisabled: true,
    },
    {
      text: 'Export',
      url: 'export-form',
      isDisabled: true,
    },
  ];
  navigateBackLinkInfo$: Observable<NavigateBackLinkInfo>;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.sidebar = this.store.select(s => s.ui.sidebar);
    this.form = this.store.select(state => state.form.current.form);
    this.navigateBackLinkInfo$ = this.activatedRoute.data.pipe(
      map(({ navigateBackLink }: FormRouteData) => navigateBackLink),
    );
    this.store.dispatch(resetCurrentForm({ formType: this.route.snapshot.data.type }));

    let title = 'form';
    switch (this.route.snapshot.data.type) {
      case FormTypeEnum.Report:
        title = 'report';
        break;
      case FormTypeEnum.Questionnaire:
        title = 'questionnaire';
        break;
      default:
        break;
    }

    this.store.dispatch(
      setContextTitle({
        title: `New ${title}`,
        position: 1,
      }),
    );
  }
}
