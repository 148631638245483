import { inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UtilsService } from '../utils.service';

@Injectable({ providedIn: 'root' })
export class ValidationsService {
  private utilsService: UtilsService = inject(UtilsService);

  minMaxValidator(form: AbstractControl): ValidationErrors | null {
    const min = form.get('min').value;
    const max = form.get('max').value;
    return min >= max ? { minMax: true } : null;
  }

  requiredWithStripHtmlValidator(control: AbstractControl): ValidationErrors | null {
    const trimmedValue = control.value.replace(/<[^>]*>|&nbsp;/g, '').trim();
    const isValid = trimmedValue !== '';
    return isValid ? null : { required: true };
  }

  uniqueLabelsValidator(parseFn = v => v.filter(x => !!x)): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const labels = parseFn(control.value);
      if (!labels.length) {
        return null;
      }
      return labels.length === new Set(labels).size ? null : { uniqueLabels: true };
    };
  }

  minVersionValidator(minVersion: string): ValidatorFn {
    return (control: AbstractControl): Record<string, unknown> | null => {
      const currentVersion = control.value;
      const isVersionLower = this.utilsService.compareVersions(currentVersion, minVersion) === -1;
      if (currentVersion && isVersionLower) {
        return { minVersion: true };
      }
      return null;
    };
  }
}
