import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, ReactiveFormsModule } from '@angular/forms';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';
import { Moment } from 'moment';

import { MatFormFieldControl } from '@angular/material/form-field';
import { UtilsService } from '../../../../core/utils.service';
import { MatDatepickerInput } from '@angular/material/datepicker';

@Component({
  selector: 'phar-datepicker-input',
  templateUrl: 'phar-datepicker-input.component.html',
  styleUrl: 'phar-datepicker-input.component.scss',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PharDatepickerInputComponent,
    },
  ],
  imports: [MatDatepickerInput, ReactiveFormsModule],
  standalone: true,
})
export class PharDatepickerInputComponent
  implements MatFormFieldControl<PharDatepickerInputComponent>, ControlValueAccessor, OnDestroy, DoCheck
{
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
  static nextId = 0;
  datepicker: UntypedFormControl;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'mf-datepicker';
  id = `mf-datepicker-${PharDatepickerInputComponent.nextId++}`;
  describedBy = '';

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input() picker: any;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  get value(): any {
    return this.datepicker.value;
  }

  set value(value) {
    this.datepicker.setValue(value);
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  get empty(): boolean {
    return !this.datepicker.value;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.datepicker.disable() : this.datepicker.enable();
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _placeholder: string;
  private _disabled = false;
  private _required = false;

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private utilService: UtilsService,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.datepicker = new UntypedFormControl(null);

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elementRef.nativeElement.querySelector('input')?.focus();
    }
  }

  writeValue(value: string | null): void {
    if (value) {
      this.value = new Date(value);
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this._formatData());
  }

  _formatData(): string | null | Moment {
    if (this.value) {
      // if (this.appConfig.config.dateTimeFormatToUTC) {
      //   return moment(this.value).utc(true);
      // }

      return this.utilService.formatDate(this.value);
    } else {
      return null;
    }
  }
}
