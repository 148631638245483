import { FormElementsEnum } from '../../form/form-elements.enum';

export const EDIT_CHECK_DISABLE_LIST = [
  FormElementsEnum.VisualAnalogScale,
  FormElementsEnum.VerbalRatingScale,
  FormElementsEnum.NumericRatingScale,
  FormElementsEnum.MultiSelect,
  FormElementsEnum.MultiSelectQuantity,
  FormElementsEnum.SingleSelect,
  FormElementsEnum.Boolean,
  FormElementsEnum.TextInput,
];
