<phar-editor-template [controlType]="FormElementsEnum.DatePicker">
  <phar-question-editor-label general [disabled]="labelsDisabled()" />
  <div response>
    <mat-label>Select all that apply</mat-label>
    <form [formGroup]="excludedOptionsForm">
      <div class="excluded-options">
        @for (option of excludedDateOptions; track index; let index = $index) {
          <div class="form-check d-flex align-items-center ms-0 ps-0 mt-1">
            <mat-checkbox color="primary" [id]="'option-' + index" [formControlName]="option.value"
              >{{ option.label }}
            </mat-checkbox>
          </div>
        }
      </div>
    </form>
  </div>
</phar-editor-template>
