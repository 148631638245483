import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, NgControl, FormGroup, FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../shared/base.class';
import { AppConfig } from '../../../../../core/config/app.config';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { UtilsService } from '../../../../../core/utils.service';

@Component({
  selector: 'phar-date-picker-value',

  templateUrl: 'date-picker-value.component.html',
  styleUrl: './date-picker-value.component.scss',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DatePickerValueComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerValueComponent
  extends BaseComponent
  implements MatFormFieldControl<DatePickerValueComponent>, ControlValueAccessor, OnDestroy, OnInit, DoCheck
{
  formGroup = new FormGroup<{ start: FormControl<Moment | null>; end: FormControl<Moment | null> }>({
    start: new FormControl<Moment | null>(null),
    end: new FormControl<Moment | null>(null),
  });

  constructor(
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    private appConfig: AppConfig,
    private utilsService: UtilsService,
    private changeDetectionRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super();

    focusMonitor
      .monitor(elementRef, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(origin => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;

  static ngAcceptInputType_required: boolean | string | null | undefined;
  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'datepicker';
  id = `datepicker-${DatePickerValueComponent.nextId++}`;
  describedBy = this.placeholder;

  get empty(): boolean {
    return !this.formGroup.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input() startLabel = 'Start date';
  @Input() endLabel = 'End Date';
  @Input() picker: any;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    return this.formGroup.value;
  }

  set value(value) {
    this.formGroup.setValue(value ?? { start: null, end: null });
    this.stateChanges.next();
  }

  private _placeholder: string;

  private _disabled = false;

  private _required = false;

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(({ start, end }) => {
      if (this.formGroup.valid) {
        this.onChange({ start: this.utilsService.formatDate(start), end: this.utilsService.formatDate(end) });
      } else {
        this.onChange(null);
      }

      this.onTouched();
    });
  }

  public get invalid(): boolean {
    return this.ngControl ? this.ngControl.invalid : false;
  }

  public get showError(): boolean {
    if (!this.ngControl) {
      return false;
    }

    const { dirty, touched } = this.ngControl;

    return this.invalid ? dirty || touched : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  onChange = (_: any) => {};
  onTouched = () => {
    this.formGroup.markAllAsTouched();
    this.formGroup.controls.end.markAsTouched();
    this.formGroup.controls.start.markAsTouched();
  };

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  handleOnBlur(): void {
    this.onTouched();
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    this.elementRef.nativeElement.focus();
  }

  writeValue(value: { start: string; end: string }): void {
    if (value && value.start && value.end) {
      this.formGroup.setValue({
        start: moment(value.start, this.appConfig.config.dateTimeFormatToISO),
        end: moment(value.end, this.appConfig.config.dateTimeFormatToISO),
      });
    } else {
      this.formGroup.setValue({ start: null, end: null });
    }
    this.onChange(value ?? null);
    this.changeDetectionRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
