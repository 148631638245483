@if (ruleForm$ | async; as form) {
  <form [formGroup]="form">
    <div formArrayName="rules" class="d-flex flex-column gap-2">
      @for (control of form.get('rules').controls; let i = $index; track i) {
        <div class="form-rule" [formGroupName]="i">
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex align-items-center cursor-pointer rule-container" (click)="toggleOpenCloseState(i)">
              <mat-icon
                class="cursor-pointer header-icon"
                [svgIcon]="openCloseState[i] ? 'arrow-down' : 'arrow-right'" />
              <span class="me-auto ms-2 text-truncate fw-bold rule-title">
                {{ control.value.title }}
              </span>
            </span>
            @if (!disabled) {
              <mat-icon
                class="cursor-pointer editor-trash-icon ms-auto header-icon"
                svgIcon="trash"
                matTooltip="Remove rule"
                (click)="removeRule(i)" />
            }
          </div>

          @if (openCloseState[i]) {
            <phar-conditional-rule [parentFormGroup]="control" [control]="currentControl$ | async" />
          }
        </div>
      }
    </div>
  </form>
}

@if (ruleForm?.value?.rules.length) {
  <mat-divider class="mt-2" />
}
@if (currentControl$ | async; as control) {
  <button
    class="mt-3"
    mat-raised-button
    [disabled]="disabled"
    (click)="addCondition(ruleForm?.value?.rules.length, control)">
    <mat-icon svgIcon="plus" />
    Add Condition
  </button>
}
