import { RuleLogicEnum } from '../../../rule/rule-logic.enum';

export const LOGIC_DATASET: Record<string, { text: string; value: RuleLogicEnum }[]> = {
  string: [
    { text: 'Equal', value: RuleLogicEnum.Equal },
    { text: 'Contains', value: RuleLogicEnum.Contains },
    { text: 'Does not Contain', value: RuleLogicEnum.DoesNotContain },
    { text: 'Is Empty', value: RuleLogicEnum.IsEmpty },
    { text: 'Is not Empty', value: RuleLogicEnum.IsNotEmpty },
  ],
  number: [
    { text: 'Greater than', value: RuleLogicEnum.GreaterThan },
    { text: 'Greater than or Equal To', value: RuleLogicEnum.GreaterThanOrEqual },
    { text: 'Less than', value: RuleLogicEnum.LessThan },
    { text: 'Less than or Equal To', value: RuleLogicEnum.LessThanOrEqualTo },
    { text: 'Is', value: RuleLogicEnum.Equal },
    { text: 'Not equal To', value: RuleLogicEnum.NotEqual },
    { text: 'Not Between', value: RuleLogicEnum.NotBetween },
    { text: 'Range', value: RuleLogicEnum.Range },
  ],
  boolean: [{ text: 'Is', value: RuleLogicEnum.Equal }],
  date: [
    { text: 'After', value: RuleLogicEnum.GreaterThan },
    { text: 'On or After', value: RuleLogicEnum.GreaterThanOrEqual },
    { text: 'Before', value: RuleLogicEnum.LessThan },
    { text: 'On or Before', value: RuleLogicEnum.LessThanOrEqualTo },
    { text: 'On', value: RuleLogicEnum.Equal },
    { text: 'Not On', value: RuleLogicEnum.Equal },
    { text: 'Not Between', value: RuleLogicEnum.NotBetween },
    { text: 'Range', value: RuleLogicEnum.Range },
  ],
  time: [
    { text: 'After', value: RuleLogicEnum.GreaterThan },
    { text: 'On or After', value: RuleLogicEnum.GreaterThanOrEqual },
    { text: 'Before', value: RuleLogicEnum.LessThan },
    { text: 'On or Before', value: RuleLogicEnum.LessThanOrEqualTo },
    { text: 'On', value: RuleLogicEnum.Equal },
    { text: 'Not On', value: RuleLogicEnum.Equal },
    { text: 'Not Between', value: RuleLogicEnum.NotBetween },
    { text: 'Range', value: RuleLogicEnum.Range },
  ],
};
