/* src/app/form/form-page/form-page.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
.form-page {
  background: white;
}
.form-page_drop-zone {
  height: 25px;
  border-radius: 5px;
  width: 100%;
  transition: all 1s;
}
.form-page_drop-zone .drop-text {
  font-size: small;
  display: none;
}
.form-page_drop-zone_footer {
  position: absolute;
  bottom: 0;
}
.form-page_drop-zone_header {
  position: absolute;
  top: 0;
}
.form-page_drop-zone--highlight {
  border: 1px dotted #9b9b9b;
  background: #e2f0e7;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.form-page_drop-zone--highlight--drag-over {
  background: rgba(66, 143, 94, 0.4);
}
.form-page_drop-zone--highlight .drop-text {
  display: block;
}
.form-page_element {
  border: 1px solid transparent;
  border-radius: 7px;
  transition: all 0.3s;
  margin: 10px 0;
}
.form-page_element__active {
  border: 1px dashed #2d4c9e;
  background: white;
}
.form-page_element:hover {
  border: 1px dashed #2d4c9e;
  background: white;
}
.form-page_element__error:hover {
  border: none;
}
.form-page_no-data {
  text-align: center;
  width: 60%;
  margin: auto;
}
.mf-question {
  margin: 5px 0;
  padding: 20px;
  background: white;
  border-radius: 7px;
  position: relative;
}
.mf-question::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
  border-radius: 7px 0 0 7px;
  background: rgba(79, 102, 171, 0.25);
  transition: background 0.5s;
}
.mf-question:hover {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
h4 {
  padding: 0 20px 20px;
}
.pageElement {
  position: relative;
}
.pageElement_buttonBlock {
  position: absolute;
  top: 10px;
  right: 10px;
}
.pageElement .pageElement_button {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s linear;
}
.pageElement .pageElement_button_edit {
  background: var(--white) !important;
  color: #2d4c9e !important;
}
.pageElement:hover .pageElement_button {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
/*# sourceMappingURL=form-page.component.css.map */
