import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { debounceTime, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { updateControlField, updateControlSettingsField } from '../../../store/actions/control.actions';
import { AppState, getState } from '../../../store/models/app.state';
import { DatasetFieldTypeEnum } from '../../../dataset/dataset-field-type.enum';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { IDatasetList } from '../../../_old/_dataset/_dataset-lists/dataset-list.interface';
import { selectCurrentDatasetDataLists } from '../../../dataset/store/dataset.state';
import { getDatasetDataLists, loadCurrentDatasetDataListById } from '../../../dataset/store/dataset.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { CONTROL_LABELS_MAX_LENGTH, INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { ValidationsService } from '../../../core/helpers/validations.service';
import { BaseComponent } from '../../../shared/base.class';

const validValues = () => null;

@Component({
  selector: 'phar-question-editor-values',
  templateUrl: './values-editor.component.html',
  styleUrls: ['./values-editor.component.scss'],
})
export class ValuesEditorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() dataFieldSelector = false;
  @Input() maxValuesOptions;
  @Input() minValuesOptions;
  @Input() showQuantity = false;

  @Input() showValueTooltip = false;
  @Input() showValue = true;

  @Input() parentDataField;

  @Output() public validate: EventEmitter<void> = new EventEmitter<void>();

  customListMode: UntypedFormControl = new UntypedFormControl(true);
  form: UntypedFormGroup;
  datasetLists$: Observable<IDatasetList[]>;
  hasPermission = false;
  displayMinValuesReachedError = false;

  readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private currentControlValidationService: CurrentControlValidationService,
    private dataFieldBindingService: DataFieldBindingService,
    private validationsService: ValidationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    const control = getState(this.store).control.current.control;
    this.hasPermission = true;
    // this.hasPermission = !isEmpty(this.permissionsService.getPermission('DatasetListRead'));
    if (this.dataFieldSelector) {
      this.customListMode.setValue(true, { emitEvent: false });
    } else {
      if (this.hasPermission) {
        if (control.settings.datasetListId)
          if (!getState(this.store).dataset.current.datasetLists.list.length) {
            this.store.dispatch(getDatasetDataLists({ datasetId: getState(this.store).dataset.current.dataset.id }));
          }
        this.datasetLists$ = this.store.select(selectCurrentDatasetDataLists);
        if (!control.settings.datasetListId) {
          this.customListMode.setValue(true);
        }
      } else {
        this.customListMode.setValue(true);
      }
    }
    this.form = this.fb.group(
      {
        values: this.buildValues(control.values),
        datasetListId: control.settings?.datasetListId,
      },
      { validators: validValues },
    );
    this.toggleValueLabelValidators(this.customListMode.value);
    this.toggleDatasetListIdValidators(!this.customListMode.value);

    if (!this.disabled) {
      this.setFormValidObservable();
    } else {
      this.form.disable();
    }

    this.currentControlValidationService.markFormAsTouched$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.markAllAsTouched();
      this.changeDetectorRef.markForCheck();
    });
    // const controlId$ = this.store.pipe(select(state => state.control.current.control.controlID), skipWhile(controlId => !controlId));
    // const values$ = this.store.pipe(select(state => state.control.current.control.values));
    // const bindDataField$ = this.store.pipe(select(state => state.control.current.control.bindDataField), skipWhile(field => !field));

    // const combined = combineLatest([ controlId$, bindDataField$ ])
    //   .pipe(
    //     map(([ controlId, field ]) => ({ controlId, field })),
    //     distinctUntilChanged((prev, current) => prev.controlId !== current.controlId && prev.field !== current.field),
    //     switchMap(({ field }) => values$.pipe(
    //       mergeMap(values => of({ values, field })),
    //       skipWhile(values => !values.values || !values.values.length),
    //       distinctUntilChanged())
    //     )
    //   );

    // this.subscription.add(combined.subscribe(result => {
    //   const { field, values } = result;
    //   const hasInvalid = this.validateHandle(values);
    //   if (hasInvalid) {
    //     this.confirmDialogService.openConfirmDialog('Values will not be compatible and they will be automatically removed! Do you wish to continue?')
    //       .subscribe(confirm => {
    //         if (confirm) {
    //           values.forEach((_, i) => (<FormArray>this.form.controls.values).removeAt(0));
    //           this.store.dispatch(updateControlField({ field: 'values', value: [] }));
    //           this.dataField = field;
    //         } else {
    //           this.store.dispatch(updateControlField({ field: 'bindDataField', value: this.dataField }));
    //         }
    //       });
    //   } else {
    //     this.dataField = field;
    //   }
    // }));

    const formValuesChanges$ = this.form.get('values').valueChanges.pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      map((values: any[]) => {
        if (this.showQuantity) {
          return values;
        }

        return values.map(({ label, value }) => ({ label, value }));
      }),
      map((values: any[]) => {
        if (this.showValue) {
          return values;
        }

        return values.map(({ label }, index) => ({ label, value: index + 1 }));
      }),
      filter(() => {
        const hasInvalid = this.validateHandle(this.form.get('values').value);
        return !hasInvalid;
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    );

    formValuesChanges$
      .pipe(
        filter(() => !this.disabled),
        debounceTime(INPUT_DEBOUNCE_TIME),
        filter(() => !this.dataFieldSelector),
        takeUntil(this.destroy$),
      )
      .subscribe(values => {
        this.displayMinValuesReachedError = false;
        this.store.dispatch(updateControlField({ field: 'values', value: values }));
      });

    formValuesChanges$
      .pipe(
        filter(() => !this.disabled),
        debounceTime(INPUT_DEBOUNCE_TIME),
        filter(() => this.dataFieldSelector),
        map(values => {
          const currentValues = getState(this.store).control.current.control.values;

          return values.map((value, index) => ({
            ...value,
            value: currentValues[index]?.value || value.value,
          }));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(values => {
        this.displayMinValuesReachedError = false;

        const control = getState(this.store).control.current.control;

        this.dataFieldBindingService.bindDataFieldsToTheControlValues(control.controlType, values);
      });

    if (control.values.length === 0) {
      this.addValue(2);
    }
    this.customListMode.valueChanges
      .pipe(
        filter(() => !this.disabled),
        takeUntil(this.destroy$),
      )
      .subscribe((customMode: boolean) => {
        if (customMode) {
          this.form.get('datasetListId').patchValue(null);
          this.toggleValueLabelValidators(true);
          this.toggleDatasetListIdValidators(false);
        } else {
          this.toggleValueLabelValidators(false);
          this.toggleDatasetListIdValidators(true);
        }
      });

    this.form
      .get('datasetListId')
      .valueChanges.pipe(
        filter(() => !this.disabled),
        takeUntil(this.destroy$),
      )
      .subscribe((value: number | null) => {
        if (value) {
          this.store.dispatch(updateControlField({ field: 'values', value: [] }));
          this.store.dispatch(loadCurrentDatasetDataListById({ id: value }));
        }
        this.store.dispatch(updateControlSettingsField('datasetListId', value));
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  addValue(numberOfItems = 1): void {
    const ctrls: UntypedFormArray = this.form.controls.values as UntypedFormArray;
    this.displayMinValuesReachedError = false;

    for (let i = 1; i <= numberOfItems; i++) {
      ctrls.push(
        this.fb.group({
          value: [' ', this.customListMode.value ? Validators.required : null],
          label: [
            '',
            this.customListMode.value
              ? [this.validationsService.requiredWithStripHtmlValidator, Validators.maxLength(this.labelMaxLength)]
              : null,
          ],
          ...(this.showQuantity && { quantity: [0, this.customListMode.value ? Validators.required : null] }),
        }),
      );
    }
  }

  removeValue(index: number): void {
    if (this.disabled) {
      return;
    }

    const ctrls: UntypedFormArray = this.form.controls.values as UntypedFormArray;

    if (!this.canRemoveResponseOptions(ctrls.controls)) {
      this.displayMinValuesReachedError = true;
      return;
    }

    if (this.dataFieldSelector) {
      const dataFieldId = getState(this.store).control.current.control.values[index]?.value;
      this.dataFieldBindingService.removeDataFields([dataFieldId]);
    }

    ctrls.removeAt(index);
  }

  buildValues(values): UntypedFormArray {
    const arrayData = [];
    for (const i in values) {
      if (values[i]) {
        arrayData.push(
          this.fb.group({
            value: [values[i].value, this.customListMode.value ? Validators.required : null],
            label: [
              values[i].label,
              this.customListMode.value
                ? [this.validationsService.requiredWithStripHtmlValidator, Validators.maxLength(this.labelMaxLength)]
                : null,
            ],
            ...(this.showQuantity && {
              quantity: [values[i].quantity, this.customListMode.value ? Validators.required : null],
            }),
          }),
        );
      }
    }
    return this.fb.array(arrayData, this.validationsService.uniqueLabelsValidator(this.parseLabelFn));
  }

  private parseLabelFn(values: { label: string }[]): string[] {
    return values.filter(x => !!x.label).map(x => x.label);
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  private validateHandle(values): boolean {
    const control = getState(this.store).control.current.control;
    const datasetFieldTypes = getState(this.store).dataset.current.dataset.datasetFieldTypes;
    let hasInvalid = false;
    let message = 'Selected data field allows only number and float values';
    // Logic for drop downs and radio buttons
    if (
      control.controlType === 'DropDownList' ||
      control.controlType === 'RadioButtonList' ||
      control.controlType === FormElementsEnum.MultiSelectQuantity
    ) {
      const field: {
        Name: string;
        ValueType: DatasetFieldTypeEnum;
      }[] = datasetFieldTypes.filter(f => f.Name === control.bindDataField);

      if (field.length === 1) {
        if (field[0].ValueType === DatasetFieldTypeEnum.boolean) {
          const { invalid, msg } = this.validateBool(values);
          message = msg;
          hasInvalid = invalid;
        }
        if (field[0].ValueType === DatasetFieldTypeEnum.float) {
          values.forEach(v => {
            if (!this.isFloat(v.value) || isNaN(+v.value)) {
              hasInvalid = true;
              message = 'Selected data field allows only float values';
            }
          });
        }
        if (field[0].ValueType === DatasetFieldTypeEnum.number) {
          values.forEach(v => {
            if (!this.isInteger(v.value) || isNaN(+v.value)) {
              hasInvalid = true;
              message = 'Selected data field allows only number values';
            }
          });
        }
      }
    }
    if (hasInvalid) {
      this.form.setErrors({ validValues: message });
    } else {
      this.form.setErrors(null);
    }
    this.validate.emit();
    return hasInvalid || this.form.invalid;
  }

  private validateBool(values): { invalid: boolean; msg: string } {
    let invalid = false;
    let msg = '';
    if (values.length > 2) {
      invalid = true;
      msg = 'Selected data field allows 2 values';
    } else if (values.length === 2) {
      const valuesToInt = [...values].map(val => {
        if (isNaN(+val.value) || (!val.value && val.value !== 0)) {
          msg = 'Selected data field allows only number and float values';
          invalid = true;
          return null;
        }
        return +val.value;
      });
      const set = {
        zero: null,
        one: null,
      };
      const exactNumberZero = valuesToInt.filter(val => val === 0);
      const exactNumberOne = valuesToInt.filter(val => val === 1);
      set.zero = exactNumberZero[0];
      set.one = exactNumberOne[0];
      if (set.zero !== 0 || set.one !== 1) {
        invalid = true;
        msg = 'Selected data field allows only 1 and 0 as values';
      }
    }
    return { invalid, msg };
  }

  private isFloat(value): boolean {
    const pattern = new RegExp('^\\d+\\.\\d', 'g');
    return pattern.test(value);
  }

  private isInteger(value): boolean {
    const pattern = new RegExp('^\\d+$', 'g');
    return pattern.test(value);
  }

  private toggleValueLabelValidators(add: boolean): void {
    const validator = add ? Validators.required : null;
    (this.form.get('values') as UntypedFormArray).controls.forEach((group: UntypedFormGroup) => {
      group.get('label').setValidators(validator);
      group.get('label').updateValueAndValidity({ emitEvent: false });
      group.get('value').setValidators(validator);
      group.get('value').updateValueAndValidity({ emitEvent: false });
    });
  }

  private toggleDatasetListIdValidators(add: boolean) {
    const validator = add ? Validators.required : null;
    this.form.get('datasetListId').setValidators(validator);
    this.form.get('datasetListId').updateValueAndValidity({ emitEvent: false });
  }

  private canRemoveResponseOptions(options: AbstractControl[]) {
    return this.minValuesOptions ? options.length > this.minValuesOptions : true;
  }
}
