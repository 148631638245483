<phar-editor-template [controlType]="FormElementsEnum.Number">
  <phar-question-editor-label general [disabled]="labelsDisabled()" />
  <form response [formGroup]="form">
    @if (form.get('inputs')['controls'][0]; as firstInputGroup) {
      <ng-container *ngTemplateOutlet="inputEditorTemplate; context: { $implicit: firstInputGroup }" />

      <div class="row w-100 gx-2">
        <div class="col-6">
          <mat-label>Units/Descriptor</mat-label>
          <mat-form-field class="w-100 remove-will-change">
            <!--<phar-html-input
              formControlName="descriptor"
              placeholder="Units/Descriptor"
              [maxLength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength"
            >
            </phar-html-input>

            <mat-hint class="hint-focus-visible">
              Entered characters {{ form.get('descriptor').value.length }}/{{ labelMaxLength }}
            </mat-hint>-->
            <input
              matInput
              type="string"
              formControlName="descriptor"
              placeholder="Units/Descriptor"
              pharInputTrim
              [maxLength]="labelMaxLength"
              [pharMaxLengthTooltip]="labelMaxLength" />

            <!--<mat-error>
                Entered characters {{ form.get('descriptor').value.length }}/{{ labelMaxLength }}
            </mat-error>-->
          </mat-form-field>
        </div>

        <div class="col-6">
          <mat-label>Additional operator</mat-label>
          <mat-form-field class="w-100">
            <mat-select formControlName="additionalOperator">
              @for (operator of additionalOperators; track $index) {
                <mat-option [value]="operator.value">
                  <span>{{ operator.label }}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }

    @if (form.get('inputs')['controls'][1]; as secondInputGroup) {
      <div class="row w-100">
        <div class="col-6">
          <mat-label>Separator</mat-label>
          <mat-form-field class="w-100">
            <mat-select formControlName="operator">
              @for (operator of operators; track $index) {
                <mat-option [value]="operator.value">
                  <span>{{ operator.label }}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 p-0"></div>
      </div>

      <ng-container *ngTemplateOutlet="inputEditorTemplate; context: { $implicit: secondInputGroup }" />
    }
    @if (form.get('inputs')['length'] >= 2 && !responseSettingsDisabled()) {
      <button mat-stroked-button (click)="removeSecondFields()">
        <mat-icon svgIcon="trash" />
        Remove value
      </button>
    }
    @if (form.get('inputs')['length'] <= 1 && !responseSettingsDisabled()) {
      <button mat-stroked-button (click)="addSecondFields()">
        <mat-icon svgIcon="plus" />
        Add value
      </button>
    }
  </form>
</phar-editor-template>

<ng-template #inputEditorTemplate let-formGroup>
  @let numberOfIntegers = formGroup.get('numberOfIntegers');
  @let numberOfDecimal = formGroup.get('numberOfDecimals');
  <div class="row w-100 gx-2 pb-3" [formGroup]="formGroup">
    <div class="col-6">
      <mat-label class="required-field">Integer places</mat-label>
      <mat-form-field class="w-100" subscriptSizing="dynamic">
        <input
          formControlName="numberOfIntegers"
          matInput
          placeholder="Number"
          type="number"
          [min]="minNumberOfIntegerPlaces"
          [max]="maxNumberOfIntegerAndDecimalPlaces" />

        <mat-error>
          @if (numberOfIntegers.hasError('required')) {
            Field is <strong>required</strong>
          } @else if (numberOfIntegers.hasError('min')) {
            Value cannot be lower than <strong>{{ minNumberOfIntegerPlaces }}</strong>
          } @else if (numberOfIntegers.hasError('max')) {
            Value cannot be greater than <strong>{{ maxNumberOfIntegerAndDecimalPlaces }}</strong>
          }
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-label class="required-field">Decimal places</mat-label>
      <mat-form-field class="w-100" subscriptSizing="dynamic">
        <input
          formControlName="numberOfDecimals"
          matInput
          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
          placeholder="Number"
          type="number"
          [min]="minNumberOfDecimalPlaces"
          [max]="maxNumberOfIntegerAndDecimalPlaces" />

        <mat-error>
          @if (numberOfDecimal.hasError('required')) {
            Field is <strong>required</strong>
          } @else if (numberOfDecimal.hasError('min')) {
            Value cannot be lower than <strong>{{ minNumberOfDecimalPlaces }}</strong>
          } @else if (numberOfDecimal.hasError('max')) {
            Value cannot be greater than <strong>{{ maxNumberOfIntegerAndDecimalPlaces }}</strong>
          }
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-template>
