/* src/app/form/form-details/form-details.component.scss */
#workspace-form mat-form-field {
  display: block;
}
#workspace-form .amend-form-badge,
#workspace-form .original-form-badge {
  border-radius: 0.5rem;
  color: #272931;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  font-weight: 600;
  min-width: 65px;
  margin-left: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
#workspace-form .amend-form-badge:hover,
#workspace-form .original-form-badge:hover {
  background-color: #f6f6f7;
}
#workspace-form .amend-form-badge_icon,
#workspace-form .original-form-badge_icon {
  margin-right: 0.25rem;
}
#workspace-form .amend-form-badge_form-name,
#workspace-form .original-form-badge_form-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=form-details.component.css.map */
