import {
  AfterViewInit,
  Component,
  computed,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { RuleForm } from '../conditional-rules-editor.component';
import { RuleActionEnum } from '../../../../../rule/rule-action.enum';
import { Observable } from 'rxjs';
import { ControlModel } from '../../../../control.model';
import {
  selectCurrentFormStateForm,
  selectCurrentFormStateFormPopulatedQuestions,
} from '../../../../../form/store/form.state';
import { filter, map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators';
import { QuestionModel } from '../../../../question.model';
import { BaseComponent } from '../../../../../shared/base.class';
import { AppState } from '../../../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { getSelectionType, SelectionType } from '../../selection-types';
import { LOGIC_DATASET } from '../../logic-dataset';
import { RuleLogicEnum } from '../../../../../rule/rule-logic.enum';
import { ElementsEnum } from '../../../../../form/form-elements.enum';
import { MatSelect } from '@angular/material/select';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormModel } from '../../../../../form/form.model';

@Component({
  selector: 'phar-conditional-rule',
  templateUrl: 'conditional-rule.component.html',
  styleUrl: './conditional-rule.component.scss',
})
export class ConditionalRuleComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) parentFormGroup: FormGroup<RuleForm>;
  @Input({ required: true }) control: ControlModel;

  @ViewChild('selectQuestion') matSelectQuestion: MatSelect;

  injector = inject(Injector);
  formControlsWithCurrent: Signal<ControlModel[]>;
  formControls: Signal<ControlModel[]>;
  isRangeSelected$: Observable<boolean>;
  responseOptions: { label?: string; value: any }[] = [];
  ruleActions = [
    {
      label: 'Hide Question',
      action: RuleActionEnum.Hide,
    },
    {
      label: 'Show Question',
      action: RuleActionEnum.Show,
    },
  ];
  selectionType: SelectionType;
  conditions: { label?: string; value: any }[] = [
    { value: '', label: 'N/A' },
    { value: RuleLogicEnum.And, label: 'And' },
    { value: RuleLogicEnum.Or, label: 'Or' },
  ];

  constructor(private store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    this.formControlsWithCurrent = toSignal(
      this.store.select(selectCurrentFormStateFormPopulatedQuestions).pipe(
        withLatestFrom(this.store.select(selectCurrentFormStateForm)),
        map(([questions, form]: [Record<string, QuestionModel>, FormModel]) => {
          //key - questionId, value - order on whole form
          const questionsOrderMap: Record<number, number> = {};
          form.body.pages.forEach(page => {
            const questionsOrderMapLength = Object.keys(questionsOrderMap).length;
            page.questions.forEach(({ order, id }) => {
              questionsOrderMap[id] = questionsOrderMapLength + order;
            });
          });

          return Object.values(questions).sort((a, b) => questionsOrderMap[a.id] - questionsOrderMap[b.id]);
        }),
        map((questions: QuestionModel[]) => {
          if (this.control.inGroup) {
            const question = questions.find((question: QuestionModel) =>
              question.controls.find(control => control.controlID === this.control.controlID),
            );
            if (!question) {
              return [];
            }
            return question.controls;
          }

          const nestedControls = questions
            .filter(question => !question.isGroup)
            .map(question => question.controls as ControlModel[]);

          return nestedControls.reduce((acc, controls) => acc.concat(controls), []);
        }),
        map((controls: ControlModel[]) =>
          controls.filter((control: ControlModel) => control.controlType !== ElementsEnum.DropZone),
        ),
      ),
      {
        injector: this.injector,
      },
    );

    this.formControls = computed(() => {
      return this.formControlsWithCurrent().filter(
        (control: ControlModel) => control.controlID !== this.control.controlID,
      );
    });

    this.selectionType = getSelectionType(this.control);
    if (this.selectionType === SelectionType.Dropdown) {
      this.responseOptions = this.getControlResponseOptions(this.control);
    }

    this.parentFormGroup.controls.logic.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.parentFormGroup.controls.controlResponseValueIndex.setValue(null);
    });

    this.parentFormGroup.controls.condition.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(condition => {
      const additionalValueFormCtrl = this.parentFormGroup.controls.additionalValue;
      if (!condition) {
        if (additionalValueFormCtrl.enabled) {
          additionalValueFormCtrl.clearValidators();
          additionalValueFormCtrl.setValue(null);
          additionalValueFormCtrl.disable();
          additionalValueFormCtrl.updateValueAndValidity();
        }
      } else {
        if (additionalValueFormCtrl.disabled) {
          additionalValueFormCtrl.enable();
          additionalValueFormCtrl.addValidators(Validators.required);
          additionalValueFormCtrl.updateValueAndValidity();
        }
      }
    });

    this.isRangeSelected$ = this.parentFormGroup.controls.logic.valueChanges.pipe(
      map(value => value === RuleLogicEnum.NotBetween || value === RuleLogicEnum.Range),
      shareReplay(1),
    );
  }

  ngAfterViewInit(): void {
    this.scrollVisibleOnFormQuestionOptionIntoView();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private scrollVisibleOnFormQuestionOptionIntoView(): void {
    this.matSelectQuestion.openedChange
      .pipe(
        filter(isOpened => isOpened),
        filter(() => {
          const panel = this.matSelectQuestion.panel?.nativeElement;
          return panel && panel.scrollHeight > panel.clientHeight;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const pageOptionsIndent = this.control.pageIndex + 1;
        const currentControlIndex = this.formControlsWithCurrent().findIndex(
          control => this.control.controlID === control.controlID,
        );
        const previousOptionIndex = currentControlIndex - 1 + pageOptionsIndent;

        if (currentControlIndex >= 0 && this.matSelectQuestion.options.length > previousOptionIndex) {
          this.matSelectQuestion._scrollOptionIntoView(previousOptionIndex);
        }
      });
  }

  private getControlResponseOptions(control: ControlModel): { label?: string; value: any }[] {
    return control.values.map(({ label, value }) => ({ label, value })) || [];
  }

  protected readonly SelectionType = SelectionType;
  protected readonly LOGIC_DATASET = LOGIC_DATASET;
}
