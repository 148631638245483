import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/models/app.state';
import { updateQuestionField } from '../../store/question.actions';

@Component({
  selector: 'phar-question-builder-editor-title',
  templateUrl: './question-builder-editor-title.component.html',
  styleUrls: ['./question-builder-editor-title.component.scss'],
})
export class QuestionBuilderEditorTitleComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', Validators.required],
      info: '',
    });

    this.subscription.add(
      this.store
        .select(state => state.question.current.question)
        .subscribe(question => {
          this.form.patchValue(question, { emitEvent: false });
        }),
    );

    this.subscription.add(
      this.form
        .get('title')
        .valueChanges.pipe(debounceTime(500))
        .subscribe(questionTitle => {
          this.store.dispatch(updateQuestionField({ field: 'title', value: questionTitle }));
        }),
    );

    this.subscription.add(
      this.form
        .get('info')
        .valueChanges.pipe(debounceTime(500))
        .subscribe(questionInfo => {
          this.store.dispatch(updateQuestionField({ field: 'info', value: questionInfo }));
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
