<div [formGroup]="form">
  <h6>Title</h6>
  <mat-form-field>
    <textarea
      formControlName="title"
      cdkAutosizeMaxRows="20"
      cdkAutosizeMinRows="1"
      matInput
      cdkTextareaAutosize
      placeholder="Add question title"
      [maxlength]="50"
      [pharMaxLengthTooltip]="50">
    </textarea>
    <mat-error> Title is <strong>required</strong> </mat-error>
  </mat-form-field>
  <h6>Info</h6>
  <mat-form-field>
    <textarea
      formControlName="info"
      cdkAutosizeMaxRows="20"
      cdkAutosizeMinRows="1"
      matInput
      cdkTextareaAutosize
      placeholder="Add info section"
      [maxlength]="50"
      [pharMaxLengthTooltip]="50">
    </textarea>
  </mat-form-field>
</div>
