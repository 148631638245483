import { FormElementsEnum } from '../../../form/form-elements.enum';
import { ControlModel } from '../../control.model';

export const DROPDOWN_SELECTION_COMPONENTS = [
  FormElementsEnum.MultiSelect,
  FormElementsEnum.MultiSelectQuantity,
  FormElementsEnum.Boolean,
  FormElementsEnum.SingleSelect,
  FormElementsEnum.VerbalRatingScale,
];

export const NUMBER_SELECTION_COMPONENTS = [
  FormElementsEnum.VisualAnalogScale,
  FormElementsEnum.NumericRatingScale,
  FormElementsEnum.Number,
];

export const STRING_SELECTION_COMPONENTS = [FormElementsEnum.TextInput];

export const DATE_SELECTION_COMPONENTS = [FormElementsEnum.DatePicker];
export const TIME_SELECTION_COMPONENTS = [FormElementsEnum.TimePicker];

export function isDateSelectionComponent(control: ControlModel): boolean {
  return DATE_SELECTION_COMPONENTS.includes(control.controlType as FormElementsEnum);
}

export function isDropdownSelectionComponent(control: ControlModel): boolean {
  return DROPDOWN_SELECTION_COMPONENTS.includes(control.controlType as FormElementsEnum);
}

export function isNumberSelectionComponent(control: ControlModel): boolean {
  return NUMBER_SELECTION_COMPONENTS.includes(control.controlType as FormElementsEnum);
}

export function isStringSelectionComponent(control: ControlModel): boolean {
  return STRING_SELECTION_COMPONENTS.includes(control.controlType as FormElementsEnum);
}

export function isTimeSelectionComponent(control: ControlModel): boolean {
  return TIME_SELECTION_COMPONENTS.includes(control.controlType as FormElementsEnum);
}

export function getSelectionType(control: ControlModel): SelectionType {
  if (isDateSelectionComponent(control)) {
    return SelectionType.Date;
  } else if (isDropdownSelectionComponent(control)) {
    return SelectionType.Dropdown;
  } else if (isNumberSelectionComponent(control)) {
    return SelectionType.Number;
  } else if (isStringSelectionComponent(control)) {
    return SelectionType.String;
  } else if (isTimeSelectionComponent(control)) {
    return SelectionType.Time;
  }
}

export enum SelectionType {
  Date = 'date',
  Dropdown = 'dropdown',
  Number = 'number',
  String = 'string',
  Time = 'time',
}
