import { Component, inject, Injector, OnDestroy, OnInit, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import { Observable, of, Subject } from 'rxjs';
import { FormModel } from '../form.model';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  dispatchedCreateUpdateForm,
  pendingCreateUpdateForm,
  releaseForm,
  searchFormById,
  successCreateUpdateForm,
  updateForm,
  updateFormField,
  updateReleasedForm,
} from '../store/form.actions';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { CurrentFormManage, selectCurrentFormState, selectCurrentFormStateForm } from '../store/form.state';
import { UtilsService } from '../../core/utils.service';
import { VersionInputDialogComponent } from '../../shared/version-input-dialog/version-input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Statuses } from '../../shared/models/statuses.enum';
import { globalLoading } from '../../store/actions/ui.actions';
import { selectLoaders } from '../../store/reducers/ui.reducer';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'phar-form-approvals',
  templateUrl: 'form-approvals.component.html',
  styleUrls: ['form-approvals.component.scss'],
})
export class FormApprovalsComponent implements OnInit, OnDestroy {
  form$: Observable<FormModel>;
  formState$: Observable<CurrentFormManage>;
  isLoading: Signal<boolean>;
  private injector = inject(Injector);
  private readonly destroy$ = new Subject<null>();

  constructor(
    private store: Store<AppState>,
    private confirmDialog: PharConfirmDialogService,
    private router: Router,
    private utilsService: UtilsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.formState$ = this.store.select(selectCurrentFormState);
    this.form$ = this.store.select(selectCurrentFormStateForm).pipe(distinctUntilChanged());
    this.isLoading = toSignal(
      this.store.select(selectLoaders(searchFormById, updateReleasedForm, releaseForm, updateForm)),
      { injector: this.injector },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleStatusChange(event: { status: Statuses }): void {
    this.setFormStatus(event.status as Statuses, true);
  }

  setFormStatus(formStatus: Statuses, isLocked: boolean): void {
    this.form$
      .pipe(
        take(1),
        switchMap((form: FormModel) =>
          this.utilsService.formExportApprovalEmptyQuestionValidation(form, this.confirmDialog, this.router, 'approve'),
        ),
        filter(form => !!form),
        switchMap(form => {
          if (formStatus !== Statuses.Released) {
            return of(form);
          }

          return this.utilsService.formExportApprovalUnresolvedCommentsValidation(
            form,
            this.confirmDialog,
            this.router,
          );
        }),
        // switchMap(form => {
        //   if (formStatus !== Statuses.Released) {
        //     return of(form);
        //   }
        //
        //   return this.confirmDialog
        //     .openConfirmDialog(
        //       `Have all reviewers approved the questionnaire?`,
        //       `${form.name} V${form.version}`,
        //       'Yes',
        //       'No',
        //     )
        //     .pipe(
        //       filter(isConfirmed => !!isConfirmed),
        //       switchMap(() =>
        //         this.confirmDialog.openConfirmDialog(
        //           `Do you want to update the version number to ${this.utilsService.incrementVersion(form.version, 'major')}?`,
        //           `${form.name} V${form.version}`,
        //           'Yes',
        //           'No',
        //         ),
        //       ),
        //       switchMap(isConfirmed => {
        //         if (isConfirmed) {
        //           return of(this.utilsService.incrementVersion(form.version, 'major'));
        //         }
        //
        //         return this.dialog
        //           .open(VersionInputDialogComponent, {
        //             width: '300px',
        //             data: {
        //               title: `${form.name} V${form.version}`,
        //               defaultVersion: form.version || '0.1',
        //             },
        //           })
        //           .afterClosed();
        //       }),
        //       tap(version => {
        //         if (version) {
        //           this.store.dispatch(
        //             updateFormField({
        //               field: 'version',
        //               value: version,
        //             }),
        //           );
        //         }
        //       }),
        //     );
        // }),
        switchMap(() => {
          const currentDate: string = new Date().toJSON();
          const dateFieldToUpdate: string = (() => {
            switch (true) {
              case formStatus === Statuses.PendingReview:
                return 'dateSentForReview';
              case formStatus === Statuses.UnderReview:
                return 'dateReviewStarted';
              case formStatus === Statuses.Released:
                return 'releaseDate';
            }
          })();

          if (dateFieldToUpdate) {
            this.store.dispatch(updateFormField({ field: dateFieldToUpdate, value: currentDate }));
          }

          if (formStatus === Statuses.Released) {
            // call different endpoint - we need to create the form in the admin app
            this.utilsService.dispatchActions(this.store, [
              pendingCreateUpdateForm({ pending: true }),
              dispatchedCreateUpdateForm({ dispatched: true }),
              successCreateUpdateForm({ success: false }),
              globalLoading(true),
            ]);
            const form = getState(this.store).form.current.form;
            if (form.isAmended) {
              this.store.dispatch(updateReleasedForm({ formId: form.id, addLoader: true }));
            } else {
              this.store.dispatch(releaseForm({ formId: form.id, addLoader: true }));
            }
          } else {
            this.store.dispatch(updateFormField({ field: 'formStatus', value: formStatus }));
            this.store.dispatch(updateFormField({ field: 'isLocked', value: isLocked }));
            const form = getState(this.store).form.current.form;

            this.store.dispatch(updateForm({ form, addLoader: true }));
          }

          return this.formState$;
        }),
        filter(({ success, pending, dispatched }) => {
          return success && !pending && !dispatched;
        }),
        map(({ form }) => {
          return form;
        }),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe((form: FormModel) => {
        if (formStatus === Statuses.Released) {
          this.confirmDialog.openConfirmDialog(
            '',
            `${form.name} is now released and has moved to the library.`,
            'Got it',
            '',
          );
        }
      });
  }

  protected readonly Statuses = Statuses;
}
