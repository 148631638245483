<phar-editor-template [controlType]="FormElementsEnum.MultiSelectQuantity">
  <phar-question-editor-label general [disabled]="labelsDisabled()" />
  <phar-question-editor-values
    response
    [disabled]="responseSettingsDisabled()"
    [dataFieldSelector]="true"
    [maxValuesOptions]="20"
    [minValuesOptions]="2"
    [showQuantity]="true"
    [showValueTooltip]="true" />
</phar-editor-template>
