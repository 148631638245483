import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RuleLogicEnum } from '../../../../../rule/rule-logic.enum';
import { BaseComponent } from '../../../../../shared/base.class';
import { CheckForm } from '../edit-checks-editor.component';
import { distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'phar-edit-check-rule',
  templateUrl: 'edit-check-rule.component.html',
  styleUrl: './edit-check-rule.component.scss',
})
export class EditCheckRuleComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input({ required: true }) parentFormGroup: FormGroup<CheckForm>;
  @Input({ required: true }) conditions: { text: string; value: RuleLogicEnum }[];
  @Input({ required: true }) controlType: string;

  shouldShowTwoValueInputs$: Observable<boolean>;

  get valueFormCtrl(): FormControl<string | null> {
    return this.parentFormGroup.controls.value;
  }

  ngOnInit() {
    this.shouldShowTwoValueInputs$ = this.parentFormGroup.controls.response.valueChanges.pipe(
      startWith(this.parentFormGroup.value.response),
      map((value: string) => {
        return value && (value === RuleLogicEnum.Range || value === RuleLogicEnum.NotBetween);
      }),
      shareReplay(1),
    );
    this.initSubscriptions();

    this.checkInitialValues();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initSubscriptions(): void {
    this.parentFormGroup.controls.response.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: (val: string) => {
          this.manageValueFields(val);
        },
      });
  }

  private checkInitialValues(): void {
    this.manageValueFields(this.parentFormGroup.value.response, false);
  }

  private manageValueFields(response: string, emitEvent = true): void {
    if (!response) {
      return;
    }
    // two conditions below are when type is string
    if (response === RuleLogicEnum.IsEmpty || response === RuleLogicEnum.IsNotEmpty) {
      this.toggleValueField(false, emitEvent);
      return;
    }
    if (this.valueFormCtrl.disabled) {
      this.toggleValueField(true, emitEvent);
      return;
    }
  }

  private toggleValueField(enable: boolean, emitEvent: boolean): void {
    if (enable) {
      this.valueFormCtrl.enable({ emitEvent });
      this.valueFormCtrl.markAsTouched();
      return;
    }

    this.valueFormCtrl.disable({ emitEvent });
    this.valueFormCtrl.patchValue(null, { emitEvent });
  }
}
