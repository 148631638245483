<!--<mat-toolbar class="page-toolbar">
  <div class="mfm-second-header_left">
    <mfm-context-header></mfm-context-header>
  </div>
  &lt;!&ndash; <div class="mfm-second-header_center">
    <mfm-third-level-menu></mfm-third-level-menu>
  </div> &ndash;&gt;
  <div class="mfm-second-header_right"></div>
</mat-toolbar>-->

@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a class="phar-back-link" [routerLink]="navigateBackLinkInfo.routerLink">
    <mat-icon svgIcon="arrow-left" />
    {{ navigateBackLinkInfo.title }}
  </a>
}

<div class="d-flex flex-column">
  <phar-second-level-menu [links]="secondLevelLinks">
    @if (form$ | async; as form) {
      <phar-status-badge
        class="form-status-badge d-flex align-center"
        [status]="form.formStatus"
        [type]="EntityType.Form" />
    }
  </phar-second-level-menu>
  <main class="context-mainContent scroll-holder" [class.scroll-holder--no-header]="!!!(header$ | async)">
    <router-outlet />
  </main>
</div>
