import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionModule } from '../../../../question/question.module';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SharedModule } from '../../../shared.module';
import { AppState } from '../../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { INPUT_DEBOUNCE_TIME } from '../../../../core/config/app.constants';
import { updateQuestionField } from '../../../../question/store/question.actions';
import { CurrentControlValidationService } from '../../../services/current-control-validation.service';
import { EMPTY, Observable } from 'rxjs';
import { QuestionModel } from '../../../../question/question.model';
import { FormTypeEnum } from '../../../../form/form.model';
import { selectCurrentQuestionPendingChanges } from '../../../../question/store/question.state';
import { PendingChangesService } from '../../../pending-changes.service';
import { QuestionEditorBaseComponent } from '../../../../question/editors/question-editor-base/question-editor-base.component';

interface QuestionGroupForm {
  title: FormControl<string>;
  label: FormControl<string>;
  info: FormControl<string>;
  allowedIterations: FormControl<number>;
  iterationOnNewPage: FormControl<boolean>;
  isRepeatable: FormControl<boolean>;
}

@Component({
  selector: 'phar-question-group-editor',
  templateUrl: 'question-group-editor.component.html',
  styleUrl: './question-group-editor.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    QuestionModule,
    CdkTextareaAutosize,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class QuestionGroupEditorComponent extends QuestionEditorBaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) question: QuestionModel;
  @Input({ required: true }) formType: FormTypeEnum;
  hasPendingChanges$: Observable<boolean>;
  form: FormGroup<QuestionGroupForm> = new FormGroup<QuestionGroupForm>({
    title: new FormControl('', [Validators.required]),
    label: new FormControl(''),
    info: new FormControl(''),
    allowedIterations: new FormControl(1, [Validators.required, Validators.min(1)]),
    isRepeatable: new FormControl(false),
    iterationOnNewPage: new FormControl(false),
  });
  labelsDisabledControlKeys: (keyof QuestionGroupForm)[] = ['title', 'label', 'info'];
  responseSettingsDisabledControlKeys: (keyof QuestionGroupForm)[] = [
    'allowedIterations',
    'isRepeatable',
    'iterationOnNewPage',
  ];

  constructor(
    protected store: Store<AppState>,
    protected pendingChangesService: PendingChangesService,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormValues();
    this.initSubscriptions();
    this.setFormValidObservable();

    this.currentControlValidationService.markFormAsTouched$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.markAllAsTouched();
    });
    if (this.formType !== FormTypeEnum.Questionnaire) {
      this.form.controls.iterationOnNewPage.disable();
    }
    this.hasPendingChanges$ = this.store.select(selectCurrentQuestionPendingChanges);

    if (this.labelsDisabled()) {
      this.labelsDisabledControlKeys.forEach(key => {
        this.form.get(key).disable();
      });
    }

    if (this.responseSettingsDisabled()) {
      this.responseSettingsDisabledControlKeys.forEach(key => {
        this.form.get(key).disable();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      Object.prototype.hasOwnProperty.call(changes, 'question') &&
      !changes.question.isFirstChange() &&
      changes.question.currentValue.id !== changes.question.previousValue.id
    ) {
      this.initFormValues();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  saveGroup(): void {
    this.pendingChangesService.triggerSave(this.question.id);
  }

  triggerDiscard(): void {
    this.pendingChangesService.triggerDiscardChanges();
  }

  private initFormValues() {
    if (this.question) {
      this.form.patchValue(this.question, { emitEvent: false });
    }

    if (this.form.controls.isRepeatable) {
      this.toggleAllowedIterations(this.form.controls.isRepeatable.value, false);
    }
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  private initSubscriptions(): void {
    this.form.controls.title.valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.store.dispatch(updateQuestionField({ field: 'title', value }));
      },
    });
    this.form.controls.label.valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.store.dispatch(updateQuestionField({ field: 'label', value }));
      },
    });

    this.form.controls.info.valueChanges.pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.store.dispatch(updateQuestionField({ field: 'info', value }));
      },
    });

    this.form.controls.isRepeatable.valueChanges
      .pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe({
        next: value => {
          this.store.dispatch(updateQuestionField({ field: 'isRepeatable', value }));
          this.toggleAllowedIterations(value);
        },
      });

    this.form.controls.allowedIterations.valueChanges
      .pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe({
        next: value => {
          this.store.dispatch(updateQuestionField({ field: 'allowedIterations', value }));
        },
      });

    this.form.controls.iterationOnNewPage.valueChanges
      .pipe(debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe({
        next: value => {
          this.store.dispatch(updateQuestionField({ field: 'iterationOnNewPage', value }));
        },
      });
  }

  private toggleAllowedIterations(toggle: boolean, emitEvent = true): void {
    if (toggle) {
      this.form.controls.allowedIterations.enable({ emitEvent });
      this.form.controls.allowedIterations.markAsTouched();
    } else {
      this.form.controls.allowedIterations.disable({ emitEvent });
      this.form.controls.allowedIterations.setValue(null, { emitEvent });
    }
  }

  protected readonly FormTypeEnum = FormTypeEnum;
}
