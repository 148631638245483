@if (checksForm$ | async; as form) {
  <form [formGroup]="form">
    <div formArrayName="checks" class="d-flex flex-column gap-2">
      @for (control of form.get('checks').controls; let i = $index; track i) {
        <div class="form-rule" [formGroupName]="i">
          <div class="d-flex justify-content-between align-items-center">
            <span class="d-flex align-items-center cursor-pointer text-truncate" (click)="toggleOpenCloseState(i)">
              <mat-icon
                class="cursor-pointer header-icon"
                [svgIcon]="openCloseState[i] ? 'arrow-down' : 'arrow-right'" />
              <span class="me-auto ms-2 fw-bold rule-title">
                {{ control.value.title }}
              </span>
            </span>
            <mat-icon
              class="editor-trash-icon ms-auto header-icon"
              svgIcon="trash"
              matTooltip="Remove rule"
              (click)="removeRule(i)" />
          </div>

          @if (openCloseState[i]) {
            <phar-edit-check-rule
              [parentFormGroup]="control"
              [conditions]="conditions$ | async"
              [controlType]="controlType" />
          }
        </div>
      }
    </div>
  </form>
}

@if (checksForm?.value?.checks.length) {
  <mat-divider class="mt-2" />
}
<button class="mt-3" mat-raised-button [disabled]="disabled" (click)="addCondition(checksForm?.value?.checks.length)">
  <mat-icon svgIcon="plus" />
  Add Check
</button>
