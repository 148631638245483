<div class="editor-container">
  <form class="editor-content" [formGroup]="footerForm">
    <mat-label class="required-field">Footer text</mat-label>
    <mat-form-field class="w-100">
      <textarea
        class="text-area-scrollable"
        cdkAutosizeMaxRows="20"
        cdkAutosizeMinRows="1"
        matInput
        cdkTextareaAutosize
        pharInputTrim
        placeholder="Enter header text"
        formControlName="text"
        [maxlength]="2000"
        [pharMaxLengthTooltip]="2000"></textarea>
      <mat-error> Footer is <b>required</b> </mat-error>
    </mat-form-field>
    <div>
      <mat-label>Site level</mat-label>
      <mat-slide-toggle color="primary" formControlName="isSiteLevel" />
    </div>
  </form>
  <div class="editor-actions d-flex align-items-center">
    <button
      class="me-auto btn-small"
      mat-raised-button
      [disabled]="(hasPendingChanges$ | async) === false"
      (click)="discardChanges()">
      <mat-icon [svgIcon]="'close'" />
      <b>Discard</b>
    </button>

    <button
      mat-raised-button
      class="ms-auto btn-small button-success"
      [disabled]="(hasPendingChanges$ | async) === false"
      (click)="saveFooterChanges()">
      <mat-icon svgIcon="check" />
      <b>Save</b>
    </button>
  </div>
</div>
