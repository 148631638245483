<phar-list-template #listTemplate>
  <div listHeader>
    @if (isAddButtonVisible) {
      <div class="d-flex flex-row justify-content-between mt-2 px-4">
        <button id="add-form" class="ms-auto" mat-stroked-button (click)="goToCreateNew()">
          <mat-icon svgIcon="plus" />
          Add
        </button>
      </div>
    }

    <div class="d-flex flex-row justify-content-between mt-4 mb-2 ms-3">
      <phar-list-filter
        [searchField]="'name'"
        [activeFilters]="lisFilterManager.filters$ | async"
        [displayedFilterOptions]="filterableFields"
        [resultCounter]="(listView$ | async).length"
        (filterAdd)="handleFilterChange($event)"
        (filterUpdate)="handleFilterUpdate($event)" />
      <div class="d-flex flex-row justify-content-between ps-3 pe-2 ms-auto">
        <phar-card-list-switcher
          class="ms-auto"
          [ngClass]="{ 'me-3': view !== ListCardView.List }"
          [view]="view"
          (afterViewSelected)="changeView($event)" />
        <phar-list-column-selection
          class="ms-1"
          [class.d-none]="view !== ListCardView.List"
          [columns]="config.columns"
          (columnsSelectionChange)="columnsSelectionChange($event)" />
      </div>
    </div>
  </div>
  <div
    class="list-page-cards"
    listBody
    [class.list-page-cards--no-header]="inProjectContext"
    [class.scroll-holder--inSubContext]="inProjectContext"
    [class.scroll-holder--no-header]="!!!(header | async)">
    @if (view === ListCardView.Card) {
      <phar-cards-list
        uniqueIdentifier="'id'"
        [cardsType]="'form'"
        [cards]="cardView$ | async | orderBy: (sorting | async)"
        [moreOptions]="cardActionsTemplate"
        [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg" />
    } @else {
      <div class="px-3">
        @defer {
          <phar-table-list-view
            [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
            [config]="config"
            [loading]="isLoading()"
            [dataSource]="listView$ | async | orderBy: (sorting | async)"
            [parentHeight]="listTemplate.listBody.nativeElement.clientHeight"
            (rowClicked)="clickHandler($event)" />
        } @placeholder {
          <div class="w-100 text-center mt-5">Data is loading...</div>
        }
      </div>
    }
  </div>
</phar-list-template>

<ng-template #nameTemplate let-element>
  <div
    #formName
    class="text-truncate"
    [pharTooltip]="element.name"
    [pharTooltipDisabled]="formName.scrollWidth <= formName.clientWidth">
    {{ element.name }}
  </div>
</ng-template>

<ng-template #cardSubtitleTemplate let-element>
  <div class="mt-2 text-nowrap">
    <div>
      Created: {{ element.createdAt | date: 'd MMM y' }} -
      @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
        <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
      }
    </div>
    @if (element.formStatus === Statuses.Released) {
      <div>Released: {{ element.releaseDate | date: 'd MMM y' }}</div>
    } @else {
      <div>
        Modified: {{ element.updatedAt | date: 'd MMM y' }} -
        @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
          <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
</ng-template>

<ng-template #versionTemplate let-element>
  @if(element.isAmended) {
    0.{{element.versionId}}
<!--    @TODO this should be removed after the DEMO-->
  } @else {
    {{ element.version }}
  }
</ng-template>

<ng-template #updatedAtTemplate let-element>
  {{ element.updatedAt | date: 'd MMM y' }}
</ng-template>

<ng-template #typeTemplate let-element>
  <span class="list-type-black">
    {{ element.type | pharFormType }}
  </span>
</ng-template>

<ng-template #statusTemplate let-element>
  <phar-status-badge width="110px" [status]="element.formStatus" [type]="entityType.Form" />
</ng-template>

<ng-template #languageTemplate let-element>
  {{ element.languageId | pharLanguage: (languages$ | async) }}
</ng-template>

<ng-template #createdTemplate let-element>
  {{ element.createdAt | date: 'd MMM y' }}
</ng-template>

<ng-template #createdByTemplate let-element>
  @if (element.userIdCreated | pharUserById: (users$ | async); as user) {
    <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
  }
</ng-template>

<ng-template #updatedByTemplate let-element>
  @if (element.userIdUpdated | pharUserById: (users$ | async); as user) {
    <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
  }
</ng-template>

<ng-template #cardActionsTemplate let-card>
  <ng-template [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ $implicit: card.original }" />
</ng-template>

<ng-template #studiesTemplate let-element>
  <span class="fw-normal">
    @if (element.projects && element.projects.length) {
      <div
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        (mouseenter)="enter(menuTrigger)"
        (mouseleave)="leave(menuTrigger)">
        @if ((element.projects | pluck: 'name' | unique).join(', '); as projects) {
          <span class="fw-normal">
            {{ projects | shorten: 28 : '...' }}
          </span>
        }
      </div>

      <mat-menu #menu="matMenu" [class]="'project-list-menu'" [hasBackdrop]="false" [overlapTrigger]="false">
        <div (mouseenter)="overMenu = true" (mouseleave)="leave(menuTrigger)">
          @for (project of element.projects | unique: 'id'; track project.id) {
            <button mat-menu-item [routerLink]="'/dashboard/study/edit/' + project.id">{{ project.name }}</button>
          }
        </div>
      </mat-menu>
    } @else {
      -
    }
  </span>
</ng-template>

<ng-template #duplicateIconTemplate let-element>
  @if (element.formDuplicateId) {
    <div class="d-flex justify-content-center w-100">
      <mat-icon class="mx-1 list-form-name-icon" svgIcon="duplicate" />
    </div>
  }
</ng-template>

<ng-template #duplicateFormNameTemplate let-element>
  @if (element.duplicatedFormName) {
    <div
      #formName
      class="text-truncate"
      [pharTooltip]="element.duplicatedFormName"
      [pharTooltipDisabled]="formName.scrollWidth <= formName.clientWidth">
      {{ element.duplicatedFormName }}
    </div>
  }
</ng-template>

<ng-template #duplicateFormVersionTemplate let-element>
  @if (element.duplicatedFormVersion) {
    {{ element.duplicatedFormVersion }}
  }
</ng-template>

<ng-template #actionsTemplate let-element>
  <mat-icon
    class="more cursor-pointer rounded-5"
    matRipple
    [matMenuTriggerFor]="actionsMenu"
    (click)="$event.stopImmediatePropagation()"
    >more_vert</mat-icon
  >

  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    @if (element.isLocked || element.isArchived) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Preview, dataItem: element })">
        <mat-icon svgIcon="eye" />
        <span>Preview</span>
      </button>
    } @else {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Edit, dataItem: element })">
        <mat-icon svgIcon="edit" />
        <span>Edit</span>
      </button>
    }

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: FormActions.Duplicate, dataItem: element })">
      <mat-icon svgIcon="duplicate" />
      <span>Duplicate</span>
    </button>

    @if (element.type === formTypeEnum.DataCapture) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.MobilePreview, dataItem: element })">
        <mat-icon svgIcon="eye" />
        <span>Mobile Preview</span>
      </button>
    }

    @if (!element.isArchived) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Archive, dataItem: element })">
        <mat-icon svgIcon="folder" />
        <span>Archive</span>
      </button>
    }

    @if (element.formStatus === formStatusEnum.Released) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: FormActions.Amend, dataItem: element })">
        <mat-icon svgIcon="change" />
        <span>Amend</span>
      </button>
    }

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: FormActions.Delete, dataItem: element })">
      <mat-icon svgIcon="trash" />
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
