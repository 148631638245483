/* src/app/question/question-view/question-view.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
:host {
  --columns: 4;
  --gutter: 20px;
  --color: hsla(204, 80%, 72%, 0.1);
  --baseline: 3rem;
  --baseline-shift: 2rem;
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns:
    repeating-linear-gradient(
      
      to right,
      var(--color),
      var(--color) var(--column-width),
      transparent var(--column-width),
      transparent var(--repeating-width) );
}
.mf-question {
  padding: 5px 20px 5px 32px;
  border-radius: 7px;
  position: relative;
}
.mf-question::before {
  transition: background 0.5s;
}
.mf-question_title {
  padding: 0;
  margin-bottom: 0;
  cursor: pointer;
}
.mf-question_title_expand {
  position: relative;
  top: 7px;
}
.mf-question_title_error-required {
  font-size: 75%;
}
.mf-question--builder-mode {
  padding: 20px 20px 20px 32px;
}
.mf-question--builder-mode ::ng-deep .question-view_drop-zone {
  grid-gap: 40px var(--gutter);
}
.mf-question__pending-changes {
  border: 1px solid red;
  transition: all 0.3s;
}
.mf-question--question-group {
  background: rgba(227, 231, 242, 0.8);
}
.question-view_drop-zone {
  height: 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: var(--gutter);
  overflow: hidden;
  transition: all 0.3s;
}
.question-view_drop-zone--highlighted::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  content: "";
  background-image: var(--background-columns);
  background-size: var(--background-width) 100%;
  background-position: 0 var(--baseline-shift);
  z-index: 1000;
  pointer-events: none;
}
.question-view_drop-zone--expanded {
  height: auto;
  overflow: visible;
  margin-top: 10px;
}
.question-view_drop-zone_between {
  position: relative;
  top: -30px;
  height: 20px;
  z-index: 1;
}
.question-view_drop-zone_new-line {
  position: relative;
  width: 100%;
  min-height: 20px;
  transition: all 0.3s;
  border-radius: 5px;
}
.question-view_drop-zone_new-line--highlighted {
  border: 1px dotted #9b9b9b;
  background: #e2f0e7;
  z-index: 3;
}
.question-view_drop-zone_bottom {
  margin-top: 10px;
}
.builder-element {
  position: relative;
  border-radius: 7px;
  transition: all 0.3s;
  z-index: 2;
}
.builder-element--repeatable-group {
  border: 1px dashed #e3e7f2;
  background: white;
  position: relative;
  left: -20px;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% + 40px);
}
.builder-element--error {
  border: 1px solid #dd5147;
}
.builder-element_left-resizer,
.builder-element_right-resizer {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: calc(50% - 5px);
  left: -5px;
  background: #e3e7f2;
  cursor: ew-resize;
  z-index: 2;
}
.builder-element_right-resizer {
  left: auto;
  right: -5px;
}
/*# sourceMappingURL=question-view.component.css.map */
