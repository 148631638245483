/* src/app/question/question-builder/question-builder.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
:host {
  --columns: 4;
  --gutter: 20px;
  --color: hsla(204, 80%, 72%, 0.1);
  --baseline: 3rem;
  --baseline-shift: 2rem;
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns:
    repeating-linear-gradient(
      
      to right,
      var(--color),
      var(--color) var(--column-width),
      transparent var(--column-width),
      transparent var(--repeating-width) );
}
.question-builder_first-toolbar {
  font-size: 18px !important;
}
.question-builder_first-toolbar #nav-section {
  border-right: 1px solid rgba(255, 255, 255, 0.89);
}
.question-builder_first-toolbar.mat-toolbar {
  height: var(--secondary-header-height);
}
.question-builder_first-toolbar.mat-toolbar.pending-changes-color {
  background: #e69e1e;
  color: rgba(0, 0, 0, 0.89);
}
.question-builder_first-toolbar.mat-toolbar.pending-changes-color #pending-changes-icon {
  color: rgba(0, 0, 0, 0.56);
}
.question-builder_first-toolbar.mat-toolbar #action-btns {
  margin-left: 200px;
}
.question-builder_first-toolbar.mat-toolbar #action-btns #save {
  background: #4f66ab;
  color: #ffffff;
  box-shadow: none;
}
.question-builder_second-toolbar.mat-toolbar {
  height: var(--secondary-header-height-small);
  background: white;
  z-index: 10;
}
.question-builder_second-toolbar_tab-menu {
  height: 100%;
}
.question-builder_content {
  height: calc(100% - var(--secondary-header-height) - var(--secondary-header-height-small));
  background: #f4f7f9;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 310px 1fr 310px;
  grid-template-rows: 100%;
  grid-template-areas: "leftbar body rightbar";
}
.question-builder_content_body {
  grid-area: body;
  overflow-y: auto;
}
.question-builder_content_leftbar {
  grid-area: leftbar;
  height: 100%;
  background-color: white;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 10px;
  overflow-y: auto;
}
.question-builder_content_leftbar h6 {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.question-builder_content_leftbar h6 .mat-icon {
  position: relative;
  top: -4px;
}
.question-builder_content_leftbar_list {
  padding: 0 0;
}
.question-builder_content_leftbar_list > * {
  width: 50%;
  height: 120px;
  display: inline-block;
  float: left;
}
.question-builder_content_leftbar_list > *:last-child:nth-child(odd):not(:first-child) {
  width: 141px;
}
.question-builder_content_leftbar_list mfm-widget {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.question-builder_content_rightbar {
  grid-area: rightbar;
  height: 100%;
  background-color: white;
  padding: 20px 0;
  overflow-y: auto;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.question-builder_content_rightbar ::ng-deep h6 {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.question-builder_content_rightbar ::ng-deep h6 .mat-icon {
  position: relative;
  top: -4px;
}
.question-builder_rules {
  height: calc(100% - var(--secondary-header-height) - var(--secondary-header-height));
  background: #f4f7f9;
  overflow-y: auto;
}
.question-builder_rules_section_title {
  height: 60px;
  font-size: 20px;
  border-bottom: 1px solid #e8ebee;
}
.question-builder_rules_section_title span {
  padding-bottom: 4px;
}
.global-loader {
  margin-top: 40%;
}
#image-wrapper {
  border: 1px solid #6d91f7;
  width: 50px;
  height: 50px;
  border-radius: 7px;
}
#image-wrapper img {
  border-radius: 7px;
}
/*# sourceMappingURL=question-builder.component.css.map */
