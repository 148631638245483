@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a class="phar-back-link" [routerLink]="navigateBackLinkInfo.routerLink">
    <mat-icon svgIcon="arrow-left" />
    {{ navigateBackLinkInfo.title }}
  </a>
}

<div class="d-flex flex-column">
  <phar-second-level-menu [links]="secondLevelLinks" />

  <main class="context-mainContent">
    <router-outlet />
  </main>
</div>
