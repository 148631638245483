import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../../../store/models/app.state';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { controlValueChanged } from '../../store/question.actions';
import { updateControlSettingsField } from '../../../store/actions/control.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { CONTROL_LABELS_MAX_LENGTH, INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { ValidationsService } from '../../../core/helpers/validations.service';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-visual-analog-scale-editor',
  templateUrl: 'visual-analog-scale-editor.component.html',
  styleUrls: ['visual-analog-scale-editor.component.scss'],
})
export class VisualAnalogScaleEditorComponent
  extends QuestionEditorBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;

  constructor(
    private actions$: Actions,
    private fb: UntypedFormBuilder,
    protected store: Store<AppState>,
    private dataFieldBindingService: DataFieldBindingService,
    private currentControlValidationService: CurrentControlValidationService,
    private validationsService: ValidationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();

    if (!this.responseSettingsDisabled()) {
      this.updateStateOnValueChange();
      this.setFormValidObservable();
    } else {
      this.form.disable();
    }

    this.currentControlValidationService.markFormAsTouched$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.markAllAsTouched();
    });
  }

  ngAfterViewInit(): void {
    this.actions$
      .pipe(ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged), takeUntil(this.destroy$))
      .subscribe(data => {
        if (data.shouldUpdateDataField) {
          this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  private buildForm(): void {
    const settings = getState(this.store).control.current.control.settings;
    const controlLabels = settings.labels.length ? settings.labels : ['', ''];

    this.form = this.fb.group(
      {
        min: [settings.min, [Validators.required, Validators.min(0), Validators.max(1000)]],
        max: [settings.max, [Validators.required, Validators.min(0), Validators.max(1000)]],
        labels: this.fb.array(
          controlLabels.map(label =>
            this.fb.control(label, [Validators.required, Validators.maxLength(this.labelMaxLength)]),
          ),
          this.validationsService.uniqueLabelsValidator(),
        ),
      },
      {
        validators: [this.validationsService.minMaxValidator],
      },
    );
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  private updateStateOnValueChange(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form
        .get(key)
        .valueChanges.pipe(distinctUntilChanged(), debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
        .subscribe(value => {
          this.store.dispatch(updateControlSettingsField(key, value));
        });
    });
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
