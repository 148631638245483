import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { takeUntil } from 'rxjs/operators';
import { controlValueChanged } from '../../store/question.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-time-picker-editor',
  templateUrl: 'time-picker-editor.component.html',
  styleUrls: ['time-picker-editor.component.scss'],
})
export class TimePickerEditorComponent extends QuestionEditorBaseComponent implements AfterViewInit, OnDestroy {
  constructor(
    private actions$: Actions,
    private dataFieldBindingService: DataFieldBindingService,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.actions$
      .pipe(ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged), takeUntil(this.destroy$))
      .subscribe(data => {
        if (data.shouldUpdateDataField) {
          this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  protected readonly FormElementsEnum = FormElementsEnum;
}
