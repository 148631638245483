/* src/app/question/editors/phar-rules-editors/conditional-rules-editor/conditional-rules-editor.component.scss */
.form-rule {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
}
.form-rule .rule-container {
  width: calc(100% - 29px);
}
.form-rule .rule-container .header-icon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}
.form-rule .rule-container .rule-title {
  font-size: 12px;
}
/*# sourceMappingURL=conditional-rules-editor.component.css.map */
