import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../../../store/models/app.state';
import { DataFieldBindingService } from '../bind-data-field-on-label-change/data-field-binding.service';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { controlValueChanged } from '../../store/question.actions';
import { updateControlSettingsField } from '../../../store/actions/control.actions';
import { CurrentControlValidationService } from '../../../shared/services/current-control-validation.service';
import { CONTROL_LABELS_MAX_LENGTH, INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { QuestionEditorBaseComponent } from '../question-editor-base/question-editor-base.component';

@Component({
  selector: 'phar-number-input-editor',
  templateUrl: 'number-input-editor.component.html',
  styleUrls: ['number-input-editor.component.scss'],
})
export class NumberInputEditorComponent
  extends QuestionEditorBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  form: UntypedFormGroup;
  readonly operators = [
    {
      label: 'None',
      value: '',
    },
    {
      label: '/',
      value: '/',
    },
    {
      label: '+',
      value: '+',
    },
    {
      label: '-',
      value: '-',
    },
    {
      label: '=',
      value: '=',
    },
    {
      label: 'x',
      value: 'x',
    },
    {
      label: ':',
      value: ':',
    },
  ];

  readonly additionalOperators = [
    {
      value: '',
      label: 'None',
    },
    {
      value: 'allowLtOrGt',
      label: 'Allow < or >',
    },
  ];
  readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;
  readonly minNumberOfIntegerPlaces = 1;
  readonly minNumberOfDecimalPlaces = 0;
  readonly maxNumberOfIntegerAndDecimalPlaces = 10;
  protected readonly FormElementsEnum = FormElementsEnum;

  constructor(
    private actions$: Actions,
    private fb: UntypedFormBuilder,
    protected store: Store<AppState>,
    private dataFieldBindingService: DataFieldBindingService,
    private currentControlValidationService: CurrentControlValidationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();

    if (!this.responseSettingsDisabled()) {
      this.updateStateOnValueChange();
      this.setFormValidObservable();
    } else {
      this.form.disable();
    }

    this.currentControlValidationService.markFormAsTouched$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.markAllAsTouched();
    });
  }

  ngAfterViewInit(): void {
    this.actions$
      .pipe(ofType<ReturnType<typeof controlValueChanged>>(controlValueChanged), takeUntil(this.destroy$))
      .subscribe(data => {
        if (data.shouldUpdateDataField) {
          this.dataFieldBindingService.bindDataFieldToTheControl(data.control);
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.currentControlValidationService.resetFormValidObservables();
  }

  addSecondFields(): void {
    const inputs = this.form.get('inputs') as UntypedFormArray;

    inputs.push(
      this.fb.group({
        numberOfIntegers: [null, Validators.required],
        numberOfDecimals: [null, Validators.required],
      }),
    );
  }

  removeSecondFields(): void {
    const inputs = this.form.get('inputs') as UntypedFormArray;
    inputs.removeAt(inputs.length - 1);

    this.form.get('operator').setValue('');
  }

  private setFormValidObservable(): void {
    const isFormValid$ = this.form.statusChanges.pipe(
      startWith(EMPTY),
      map(() => this.form.valid),
    );

    this.currentControlValidationService.setFormValidObservable(isFormValid$);
  }

  private buildForm(): void {
    const settings = getState(this.store).control.current.control.settings;
    const inputs = settings.inputs.map(input =>
      this.fb.group({
        numberOfIntegers: [input?.numberOfIntegers, Validators.required],
        numberOfDecimals: [input?.numberOfDecimals, Validators.required],
      }),
    );

    this.form = this.fb.group({
      operator: [settings.operator],
      descriptor: [settings.descriptor, Validators.maxLength(this.labelMaxLength)],
      additionalOperator: [settings?.additionalOperator ?? ''],
      inputs: this.fb.array(inputs),
    });
  }

  private updateStateOnValueChange(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form
        .get(key)
        .valueChanges.pipe(distinctUntilChanged(), debounceTime(INPUT_DEBOUNCE_TIME), takeUntil(this.destroy$))
        .subscribe(value => {
          this.store.dispatch(updateControlSettingsField(key, value));
        });
    });
  }
}
