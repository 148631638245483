import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { QuestionBuilderComponent } from './question-builder/question-builder.component';
import { QuestionViewComponent } from './question-view/question-view.component';
import { QuestionBuilderEditorTitleComponent } from './editors/title-editor/question-builder-editor-title.component';

import { LabelEditorComponent } from './editors/label-editor/label-editor.component';
import { ValuesEditorComponent } from './editors/values-editor/values-editor.component';

import { NgxPermissionsModule } from 'ngx-permissions';

import { MatSliderModule } from '@angular/material/slider';
import { MultiSelectQuantityEditorComponent } from './editors/multi-select-quantity-editor/multi-select-quantity-editor.component';
import { MultiSelectEditorComponent } from './editors/multi-select-editor/multi-select-editor.component';
import { NumericRatingScaleEditorComponent } from './editors/numeric-rating-scale-editor/numeric-rating-scale-editor.component';
import { DataFieldBindingService } from './editors/bind-data-field-on-label-change/data-field-binding.service';
import { VerbalRatingScaleEditorComponent } from './editors/verbal-rating-scale-editor/verbal-rating-scale-editor.component';
import { SingleSelectEditorComponent } from './editors/single-select-editor/single-select-editor.component';
import { GeneralBooleanEditorComponent } from './editors/general-boolean-editor/general-boolean-editor.component';
import { VisualAnalogScaleEditorComponent } from './editors/visual-analog-scale-editor/visual-analog-scale-editor.component';
import { TextBlockEditorComponent } from './editors/text-block-editor/text-block-editor.component';
import { TextInputEditorComponent } from './editors/text-input-editor/text-input-editor.component';
import { NumberInputEditorComponent } from './editors/number-input-editor/number-input-editor.component';
import { DatePickerEditorComponent } from './editors/date-picker-editor/date-picker-editor.component';
import { TimePickerEditorComponent } from './editors/time-picker-editor/time-picker-editor.component';
import { ImageEditorComponent } from './editors/image-editor/image-editor.component';
import { EditorTemplateComponent } from './editors/editor-template/editor-template.component';
import { RulesListComponent } from './rules-list/rules-list.component';
import { ConditionalRulesEditorComponent } from './editors/phar-rules-editors/conditional-rules-editor/conditional-rules-editor.component';
import { ConditionalRuleComponent } from './editors/phar-rules-editors/conditional-rules-editor/conditional-rule/conditional-rule.component';
import { EditChecksEditorComponent } from './editors/phar-rules-editors/edit-checks-editor/edit-checks-editor.component';
import { EditCheckRuleComponent } from './editors/phar-rules-editors/edit-checks-editor/edit-check-rule/edit-check-rule.component';
import { DatePickerValueComponent } from './editors/phar-rules-editors/conditional-rules-editor/date-picker-value/date-picker-value.component';
import { ElementTypePipe } from '../shared/element-type/element-type.pipe';
import { HelpIconComponent } from '../shared/help-icon/help-icon.component';
import { PharTooltipDirective } from '../shared/directives/tooltip.directive';
import { PharDatepickerInputComponent } from '../shared/mfm-controls/mfm-date';
import { NoPropagationDirective } from '../shared/directives/no-propagation.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    // RuleModule,
    NgxPermissionsModule.forChild(),
    MatSliderModule,
    EditorTemplateComponent,
    ElementTypePipe,
    HelpIconComponent,
    PharTooltipDirective,
    PharDatepickerInputComponent,
    NoPropagationDirective,
  ],
  providers: [DataFieldBindingService],
  declarations: [
    QuestionBuilderComponent,
    QuestionViewComponent,
    QuestionBuilderEditorTitleComponent,
    // QuestionBuilderEditorRulesComponent,
    // QuestionBuilderEditorRepeatableGroupComponent,
    LabelEditorComponent,
    ValuesEditorComponent,
    MultiSelectQuantityEditorComponent,
    MultiSelectEditorComponent,
    NumericRatingScaleEditorComponent,
    VerbalRatingScaleEditorComponent,
    SingleSelectEditorComponent,
    GeneralBooleanEditorComponent,
    VisualAnalogScaleEditorComponent,
    TextBlockEditorComponent,
    TextInputEditorComponent,
    NumberInputEditorComponent,
    DatePickerEditorComponent,
    TimePickerEditorComponent,
    ConditionalRulesEditorComponent,
    ImageEditorComponent,
    RulesListComponent,
    ConditionalRuleComponent,
    EditChecksEditorComponent,
    EditCheckRuleComponent,
    DatePickerValueComponent,
    // QuestionLibraryComponent,
    // QuestionBuilderEditorTextblockComponent,
    // QuestionBuilderEditorTextboxComponent,
    // QuestionBuilderEditorNumberComponent,
    // QuestionBuilderEditorValidatorsComponent,
    // QuestionBuilderEditorBindDataFiledComponent,
    // RadioButtonListComponent,
    // CheckboxEditorComponent,
    // DropdownEditorComponent,
    // DateEditorComponent,
    // ImageUploadEditorComponent,
    // FileUploadEditorComponent,
    // SignatureEditorComponent,
    // CustomDateFormatComponent,
    // MfmScriptEditorComponent,
    // BoxedInputEditorComponent,
    // BoxedInputValidatorsComponent,
    // TableElementEditorComponent,
    // TableElementColumnEditorComponent,
    // GridRadioEditorComponent,
    // GridRadioColumnRowEditorComponent,
    // RadioImageListEditorComponent,
    // RadioImageListValueEditorComponent,
    // ImageTaggingEditorComponent,
    // MfmSliderValueLabelsComponent,
    // MfmSliderEditorComponent,
    // ImageHighlightingEditorComponent,
    // ImageHighlightingBrushesComponent,
    // CheckboxValidatorsComponent,
    // MfmTimeEditorComponent,
    // MfCustomTimeFormatComponent,
    // DataTableEditorComponent,
    // MfmHiddenInputEditorComponent,
  ],
  exports: [
    QuestionBuilderComponent,
    QuestionViewComponent,
    QuestionBuilderComponent,
    QuestionViewComponent,
    QuestionBuilderEditorTitleComponent,
    LabelEditorComponent,
    ValuesEditorComponent,
    MultiSelectQuantityEditorComponent,
    MultiSelectEditorComponent,
    NumericRatingScaleEditorComponent,
    VerbalRatingScaleEditorComponent,
    SingleSelectEditorComponent,
    GeneralBooleanEditorComponent,
    VisualAnalogScaleEditorComponent,
    TextBlockEditorComponent,
    TextInputEditorComponent,
    DatePickerEditorComponent,
    NumberInputEditorComponent,
    TimePickerEditorComponent,
    ImageEditorComponent,
    RulesListComponent,
    DatePickerValueComponent,
    // QuestionBuilderEditorRulesComponent,
    // QuestionLibraryComponent,
    // QuestionBuilderEditorRepeatableGroupComponent,
    // RadioButtonListComponent,
    // QuestionBuilderEditorTextblockComponent,
    // QuestionBuilderEditorTextboxComponent,
    // QuestionBuilderEditorNumberComponent,
    // QuestionBuilderEditorValidatorsComponent,
    // QuestionBuilderEditorBindDataFiledComponent,
    // CheckboxEditorComponent,
    // DropdownEditorComponent,
    // DateEditorComponent,
    // ImageUploadEditorComponent,
    // FileUploadEditorComponent,
    // SignatureEditorComponent,
    // CustomDateFormatComponent,
    // MfmScriptEditorComponent,
    // BoxedInputEditorComponent,
    // BoxedInputValidatorsComponent,
    // TableElementEditorComponent,
    // TableElementColumnEditorComponent,
    // GridRadioEditorComponent,
    // GridRadioColumnRowEditorComponent,
    // RadioImageListEditorComponent,
    // RadioImageListValueEditorComponent,
    // ImageTaggingEditorComponent,
    // MfmSliderValueLabelsComponent,
    // MfmSliderEditorComponent,
    // ImageHighlightingEditorComponent,
    // ImageHighlightingBrushesComponent,
    // CheckboxValidatorsComponent,
    // MfmTimeEditorComponent,
    // MfCustomTimeFormatComponent,
    // DataTableEditorComponent,
    // MfmHiddenInputEditorComponent,
  ],
})
export class QuestionModule {}
