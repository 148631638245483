/* src/app/form/form-content/form-content.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
:host ::ng-deep .mat-mdc-paginator-range-label {
  display: none;
}
.form-question-library ::ng-deep .mat-mdc-tab-label {
  min-width: 120px;
}
.form-content {
  --leftBarWidth: 300px;
  --rightBarWidth: var(--rightPanelWidth);
  --leftBarPadding: 20px 15px 20px 10px;
  --rightBarPadding: 20px 10px 20px 15px;
  height: 100%;
  background: #f4f7f9;
  display: grid;
  grid-gap: 0;
  grid-template-columns: var(--leftBarWidth) 1fr var(--rightBarWidth);
  grid-template-rows: 100%;
  grid-template-areas: "leftbar body rightbar";
}
.form-content_buttons-zone {
  min-height: 52px;
}
.form-content_buttons-zone:hover .form-content_buttons-zone_edit {
  background: rgba(246, 246, 247, 0.7);
}
.form-content_buttons-zone_edit {
  top: 0;
  right: 0;
  height: 100%;
  transition: all 0.3s;
}
.form-content--no-left-bar {
  --leftBarWidth: 0;
  --leftBarPadding: 0;
}
.form-content--no-left-bar .mat-mdc--toolbar.form-content_page-header {
  padding-left: 30px;
}
.form-content--no-left-bar .form-content_body {
  padding-left: 15px;
}
.form-content--no-right-bar {
  --rightBarWidth: 0 !important;
  --rightBarPadding: 0;
}
.form-content--no-right-bar .mat-mdc-toolbar.form-content_page-header {
  padding-right: 30px;
}
.form-content--no-right-bar .form-content_body {
  padding-right: 15px;
}
.form-content_right-bar-fullwidth {
  grid-template-columns: 0 0 1fr;
}
.form-content_right-bar-fullwidth .widgets-container_content_rightbar {
  padding: 1rem 3rem 3rem;
  padding-bottom: 0;
}
.form-content_content-fullwidth {
  grid-template-columns: 0 1fr 0;
}
.form-content .resize-handle {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ew-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease-out;
}
.form-content .resize-handle:hover {
  width: 3px;
  background-color: #4f66ab;
}
.form-content .resize-handle:hover::after {
  content: "";
  display: block;
  height: 100%;
  width: 24px;
  position: absolute;
  right: -12px;
  z-index: 1;
}
.form-content .resize-handle.resizing::after {
  width: 100px;
  right: -50px;
}
.form-content_aside-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  z-index: 11;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  border: 1px solid #babbbd;
  background-color: white;
}
.form-content_aside-button mat-icon {
  overflow: unset;
}
.form-content_aside-button mat-icon.rotate {
  rotate: 90deg;
}
.form-content_leftbar {
  grid-area: leftbar;
  height: 100%;
  background-color: white;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  position: relative;
}
.form-content_leftbar_content {
  overflow-y: auto;
  height: 100%;
  padding: var(--leftBarPadding);
}
.form-content_leftbar h6 {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.form-content_leftbar h6 .mat-icon {
  position: relative;
  top: -4px;
}
.form-content_leftbar .form-content_aside-button {
  right: 0;
}
.form-content_leftbar .form-content_aside-button.hide {
  transform: translateX(50%);
}
.form-content_leftbar .form-content_aside-button.show {
  transform: translateX(100%);
}
.form-content_leftbar_list {
  padding: 0 5px;
}
.form-content_leftbar_list > * {
  width: 130px;
  height: 120px;
  display: inline-block;
  float: left;
}
.form-content_leftbar_list > *:nth-child(even) {
  border-left: 1px solid #e8ebee;
}
.form-content_leftbar_list > *:last-child:nth-child(odd):not(:first-child) {
  border-right: 1px solid #e8ebee;
}
.form-content_leftbar_list > *:last-child:nth-child(odd):not(:first-child) {
  width: 141px;
  border-right: 1px solid #e8ebee;
}
.form-content_leftbar_list > *:nth-child(n+3) {
  border-top: 1px solid #e8ebee;
}
.form-content_body {
  grid-area: body;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
.form-content_body_header,
.form-content_body_footer {
  background-color: white;
  position: relative;
  padding-top: 40px;
}
.form-content_body_header_button-block,
.form-content_body_footer_button-block {
  top: 5px;
  right: 20px;
  position: absolute;
}
.form-content_body_header .button-block_button,
.form-content_body_footer .button-block_button {
  visibility: visible !important;
  opacity: 1 !important;
  transition-delay: 0s !important;
}
.form-content_body_header .button-block_button mat-icon,
.form-content_body_footer .button-block_button mat-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  zoom: 85%;
}
.form-content_body_header .button-block_button .mat-mdc-button-touch-target,
.form-content_body_footer .button-block_button .mat-mdc-button-touch-target {
  width: 30px;
  height: 30px;
}
.form-content_body_header_text,
.form-content_body_footer_text {
  word-break: break-word;
  min-height: 1rem;
}
.form-content_rightbar {
  grid-area: rightbar;
  height: 100%;
  background-color: white;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.form-content_rightbar .form-content_questions-libraries {
  height: calc(100% - 68px);
}
.form-content_rightbar .form-content_questions-libraries ::ng-deep .mat-mdc-tab-body-wrapper {
  height: 100%;
}
.form-content_element {
  border: 1px solid #e3e7f2;
  border-radius: 7px;
  transition: all 0.3s;
}
.form-content_element--selected,
.form-content_element:hover {
  cursor: pointer;
  border: 1px solid #2d4c9e;
  background: white;
  transition: none;
}
.form-content_element--pending-changes {
  border: 1px solid red;
}
.form-content_questions-libraries {
  flex: 1;
}
.form-content_questions-libraries ::ng-deep {
}
.form-content_questions-libraries ::ng-deep .mat-mdc-tab-header {
  display: none;
}
.form-content_questions-libraries ::ng-deep .mat-tab-mdc-body-wrapper {
  height: 100%;
}
.form-content_questions-button:first-of-type {
  border-radius: 7px 0 0 7px;
}
.form-content_questions-button:last-of-type {
  border-radius: 0 7px 7px 0;
}
.form-content_page-header {
  border-bottom: 1px solid #c7c9cd;
  top: 0;
  background: white;
  z-index: 10;
}
.form-content_page-header_title {
  display: inline-block;
  font: bold 14px BwModelica, monospace !important;
}
.form-content_page-header_count {
  font-weight: bold;
  font-size: 14px;
}
.form-content_page-header_button {
  transition: all 0.2s;
  color: #272931 !important;
}
.form-content_page-selector_element {
  width: 100%;
  height: 230px;
}
.form-content_page-selector_element--active .form-content_page-selector_card {
  border: 1px solid #4f66ab;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.form-content_page-selector_element--active .form-content_page-selector_title {
  opacity: 1;
}
.form-content_page-selector_card {
  width: 120px;
  height: 170px;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
}
.form-content_page-selector_card:hover {
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.form-content_page-selector_card:hover + .form-content_page-selector_title {
  opacity: 1;
}
.form-content_page-selector_page {
  border: 1px solid #c7c9cd;
}
.form-content_page-selector_page:hover {
  border: 1px solid #4f66ab;
}
.form-content_page-selector_new {
  background: #f4f7f9;
  border: 1px dashed #c7c9cd;
}
.form-content_page-selector_new:hover .form-content_page-selector_plus {
  border: 1px solid #4f66ab;
  color: #4f66ab;
}
.form-content_page-selector_plus {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #c7c9cd;
  font-size: 30px;
  color: #c7c9cd;
  transition: all 0.3s;
}
.form-content_page-selector_title {
  opacity: 0.6;
  transition: all 0.3s;
}
.form-content_hide-button {
  position: absolute;
  top: 10px;
}
.form-content_hide-button span {
  width: 20px;
  height: 2px;
  background: #4f66ab;
  display: inline-block;
  position: relative;
}
.form-content_hide-button span::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 4px;
  width: 12px;
  height: 2px;
  background: #4f66ab;
}
.form-content_hide-button span::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 4px;
  width: 12px;
  height: 2px;
  background: #4f66ab;
}
.form-content_hide-button_left {
  left: 10px;
}
.form-content_hide-button_right {
  right: 10px;
}
mfm-information-stripe ::ng-deep #mfm-information-stripe-container {
  position: absolute;
  z-index: 10;
}
.hide-buttons-spacer .form-content_hide-button {
  top: 35px;
}
.pending-changes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 85px;
  transform: translate(-50%, -50%);
}
#pending-changes-title,
#pending-changes-icon {
  color: orange;
}
.widgets-container_content_rightbar {
  position: relative;
}
.widgets-container_content_rightbar ::ng-deep h6 {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.widgets-container_content_rightbar ::ng-deep h6 .mat-icon {
  position: relative;
  top: -4px;
}
.widgets-container_content_rightbar .form-content_aside-button {
  left: 0;
  z-index: 11;
}
.widgets-container_content_rightbar .form-content_aside-button.hide {
  transform: translateX(-50%);
}
.widgets-container_content_rightbar .form-content_aside-button.show {
  transform: translateX(-100%);
}
/*# sourceMappingURL=form-content.component.css.map */
